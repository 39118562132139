import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layout/layout_blog.js";
import { Section } from "../../layout";
import { Image, LeftImage } from "../../components";
import { Link } from "gatsby";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p><strong parentName="p">{`Make your business visible, reach our users, and get more customers. With your
promotion on Trailguide, everyone who plans his trip or holiday gets the
valuable information where to stay in a bike friendly accomodation, where to
rent a bike, get a guided tour, a shuttle, can fix their bike, or just where
to get a good beer after biking!`}</strong>{` Klikk
`}<a parentName="p" {...{
        "href": "https://www.trailguide.no/news/lokaleaktorer"
      }}>{`her`}</a>{` for den norske versjonen.`}</p>
    <Section mdxType="Section" />
    <LeftImage src="webapp/screenshot_money.jpg" mdxType="LeftImage" />
    <h2>{`Promote your business`}</h2>
    <ul>
      <li parentName="ul">{`Promote your services in a radius of up to 30km`}</li>
      <li parentName="ul">{`Automatically be visible in all trail descriptions`}</li>
      <li parentName="ul">{`Your business is shown on the map as a point of interest`}</li>
      <li parentName="ul">{`Automatic import and update of information from your Facebook page`}</li>
    </ul>
    <Section mdxType="Section" />
    <LeftImage src="news/localservicesmapclose2.jpg" mdxType="LeftImage" />
    <h2>{`Be visible`}</h2>
    <ul>
      <li parentName="ul">{`Your website, Facebook page, e-mail, phone, and location is shown interactively`}</li>
      <li parentName="ul">{`Your services are shown as icons`}</li>
      <li parentName="ul">{`Use Trailguide if your customers ask for trails`}</li>
      <li parentName="ul">{`You can embedd an interactive trail map on your website`}</li>
    </ul>
    <Section mdxType="Section" />
    <Image src="news/facebookwebsite.jpg" mdxType="Image" />
    <p>{`All the necessary information, including the logo, infotext, location, and
all the contact information is `}<strong parentName="p">{`automatically imported`}</strong>{` from your Facebook
page. That means you don't need to care about maintaining the data on
Trailguide. If you change anything on Facebook, it is automatically changed
on Trailguide!`}</p>
    <Image src="news/trailguidephone1.jpg" mdxType="Image" />
    <p>{`Trailguide is a free web-app. We offer the ads for bike and tourism related
businesses to finance the service, and to provide valuable information for
the users at the same time. Local services benefit from `}<strong parentName="p">{`more customers`}</strong>{`
and Trailguide can stay free for all.`}</p>
    <Image src="webapp/earnmoney.jpg" mdxType="Image" />
    <p>{`The promotion is offered for a yearly fee. `}<Link to="/about" mdxType="Link">{`Please find contact information here.`}</Link></p>
    <p>{`Or go directly to the `}<a href="https://trailguide.net/order">{`order form.`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      